body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#header {
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    line-height: 1em;
    padding-top: 2em;
    padding-bottom: 5px;
}

#main {
    text-align: center;
    align-items: center;
    font-family: Arial; 
}

#footer {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: grey;
    padding: 5px 0px;
}

.lang {
    position: absolute;
    right: 0;
    margin-right: 18px;
    margin-top: 18px;
}

.lang-button {
    border: 2px solid black;
    background-color: #0071e3;
    padding: 5px 10px;
    font-size: 15px;
    cursor: pointer;
    color: white;
    border-color: #0071e3;
    border-radius: 4px;
    text-transform: uppercase;
}

.lang-button:hover {
    background: #0077ed;
}

.restaurant-name {
    text-align: center;
}

.restaurant-info {
    text-align: center;
}

.section-items {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
}

.item {
    width: 18rem;
    height: auto;
    margin: 0;
    padding: 0px 2em 0px 2em;
    float: left;
    text-align: center;
}
  
.item-image > img{
    width: 100%;
    height: auto;
}

.banner {
    display: grid;
    padding: 0em 2em;
    text-align: center;
    background-color: #87b5eb;
    color: #fff;
    font-weight: 500;
    line-height: 20px;
}
